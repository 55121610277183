$ng-select-primary-text: $input-color !default;
$ng-select-disabled-text: $text-muted !default;
$ng-select-border: $input-border-color !default;
$ng-select-bg: $input-bg !default;
$ng-select-selected: $dropdown-link-active-bg !default;
$ng-select-marked: $dropdown-link-active-bg !default;

.ng-select {
  position: relative;
  display: block;
  box-sizing: border-box;

  border-radius: $form-select-border-radius;
  border: $form-select-border-width solid $form-select-border-color;
  padding-bottom: 0;

  &.ng-select-disabled {
    background-color: $form-select-disabled-bg;

    .ng-select-container {
      .ng-value-container {
        .ng-value {
          color: $form-select-color;
        }

        .ng-placeholder {
          color: $form-select-color;
        }
      }
    }
  }

  &.ng-select-focus,
  &.ng-select-focused {
    box-shadow: $form-select-focus-box-shadow;
    border-color: $form-select-focus-border-color !important;
    outline: 0px;
  }

  &-sm {
    &.ng-select-single {
      border-radius: $form-select-border-radius;
      padding: 0rem 0.75rem;

      .ng-select-container {
        height: 2.25rem;
        min-height: 2.25rem;

        .ng-arrow-wrapper,
        .ng-clear-wrapper {
          bottom: 0.25rem;
        }

        .ng-value-container {
          border-top: 0;
        }
      }
    }
  }

  .ng-select-container {
    &:after {
      content: none;
    }

    .ng-value-container {
      .ng-value-label {
        text-transform: capitalize;
        font-weight: $form-select-font-weight;
      }

      .ng-placeholder {
        color: $form-select-color;
        display: block;
        max-width: 90%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .ng-arrow-wrapper .ng-arrow {
    opacity: 0;
  }

  .ng-dropdown-panel {
    &.ng-select-bottom {
      top: 100%;
      box-shadow: $box-shadow-lg;
      border-radius: $form-select-border-radius;
      margin-top: 0.25rem;
      border: $form-select-border-width solid $form-select-border-color;
    }

    &:not(.ng-select-multiple) {
      .ng-option-selected {
        padding-right: 2rem;
        background: $dropdown-link-active-bg;
        color: $secondary;

        // &:after {
        //   content: 'assets/images/icons/8/';
        //   position: absolute;
        //   top: 0;
        //   right: 1rem;
        //   color: $primary;
        // }
      }
    }
  }

  .ng-dropdown-panel-items {
    padding: 1rem 0;
    font-size: 0.813rem;
    border-radius: $form-select-border-radius;

    scrollbar-color: setColor('dark', 'darken-20') setColor('light', 'lighten-100');
    scrollbar-width: none; //thin;
    transition: scrollbar-color 0.33s ease-out;

    &::-webkit-scrollbar {
      scrollbar-width: none; //thin;
      width: 0.25em;
      height: 0;
    }

    &::-webkit-scrollbar-track {
      background: setColor('light', 'lighten-30');
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: setColor('dark', 'darken-20');
      border-radius: 0;
      transition: background 0.22s ease-in;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: setColor('dark', 'darken-60');
      scrollbar-color: setColor('dark', 'darken-60') setColor('light', 'lighten-40');
      transition: background 0.22s ease-in, scrollbar-color 0.22s ease-in;
    }

    .ng-option {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 1rem;
        right: 1rem;
        height: 1px;
        background: $form-select-border-color;
      }

      &.ng-option-child {
        padding: 0 1rem;
      }
    }

    .ng-optgroup {
      &.ng-option-disabled {
        padding: map-get($spacers, 2) map-get($spacers, 3);
        font-size: 0.813rem;
        font-weight: $form-select-font-weight;
        line-height: 1.2;
        height: auto;
        border-top: $form-select-border-width solid $form-select-border-color;
        border-bottom: $form-select-border-width solid $form-select-border-color;
        background: #fafafa;
      }
    }

    .ng-option-marked,
    .ng-option-selected {
      color: $body-color;
      position: relative;
      background: transparent;
    }

    .ng-option-marked {
      background: $dropdown-link-hover-bg;
    }
  }

  &.ng-select-filters {
    border-radius: 0;
    padding: 0 map-get($spacers, 3);

    &.ng-select-opened {
      .ng-placeholder {
        transform: none;
      }
    }

    .ng-select-container {
      &.ng-has-value {
        .ng-value-container {
          padding-top: 0;
        }

        .ng-clear-wrapper {
          border-top: 1px solid transparent;
          text-align: right;
        }
      }
    }

    .ng-select-container {
      min-height: 2rem;
      height: 2rem;

      .ng-value-container {
        align-items: center;
        border-top: 0;
        height: 100%;
        padding: 0;

        .ng-placeholder {
          white-space: nowrap;
          position: relative;
          transform: none;
          font-size: 13px;
          line-height: 1.2;
          font-weight: $form-select-font-weight;
          max-width: 100%;
        }
      }
    }

    .ng-dropdown-panel {
      min-width: 240px;

      &.ng-select-bottom {
        top: 115%;
      }
    }
  }

  &.floating-labels {
    border-radius: $form-select-border-radius;
    border: 1px solid;
    border-color: $form-select-border-color;
    height: $form-floating-height;
    line-height: $form-floating-line-height;
    padding: 0.5rem 0.75rem;

    &.ng-invalid,
    &.is-invalid {
      border-color: $form-feedback-invalid-color;
    }

    &.ng-select-single {
    }

    &.ng-select-multiple {
      .ng-select-container {
        .ng-arrow-wrapper,
        .ng-clear-wrapper {
          bottom: 0.65rem;
        }

        .spinner-border {
          position: relative;
          bottom: 0.65rem;
        }
      }
    }

    .ng-select-container {
      cursor: default;
      display: flex;
      outline: none;
      overflow: hidden;
      position: relative;
      width: 100%;
      color: rgba(0, 0, 0, 0.87);
      align-items: baseline;
      height: 3rem;
      min-height: 3rem;

      &.ng-has-value {
        .ng-placeholder {
          transform: translateY(-1.28125em) scale(0.85) perspective(100px) translateZ(0.001px);
          opacity: $form-floating-label-opacity;
          color: $body-color;
          font-weight: 400;
        }
      }

      .ng-value-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        flex: 1;
        align-items: stretch;
        padding: 0.4375em 0;
        border-top: 0.84375em solid transparent;
        .ng-placeholder {
          position: absolute;
          color: $body-color;
          transform-origin: left 0;
          transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
            width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        }
        .ng-input {
          bottom: 0.4375em;
        }
      }

      .ng-arrow-wrapper,
      .ng-clear-wrapper {
        bottom: 1rem;
      }
    }

    &.ng-select-focus,
    &.ng-select-focused {
      .ng-select-container {
        .ng-value-container {
          .ng-placeholder {
            opacity: $form-floating-label-opacity;
            color: #000;
          }
        }
      }
    }

    .ng-placeholder {
      transform: translateY(-0.65rem);
      font-weight: $form-select-font-weight;
    }
  }
}

@import '~@ng-select/ng-select/themes/material.theme.css';
