/* You can add global styles to this file, and also import other style files */
// @import '../node_modules/bootstrap/scss/bootstrap';

// @import '~swiper/swiper.min.css';
// @import '~swiper/swiper-bundle.min.css';

// @import "~node_modules/bootstrap/scss/maps";

// 1. Importa le tua variabili
@import 'styles/variables';

// 2. Importa le utlities modificate
@import 'styles/utilities';

// 3. Importa Bootstrap
@import '~node_modules/bootstrap/scss/bootstrap';

// 4. CSS Personalizzato
@import 'styles/custom';

// 5. Note
// Importa il font su html e modifica font su file _variables.scss;
