@import 'animate.css';
@import 'costaglyph';
@import 'alert';
@import 'ng-select';

:root {
  --swiper-theme-color: var(--bs-info);
}

::marker {
  color: $link-color;
  // font-size: 1.2rem;
}

ol,
ul {
  padding-left: 1rem;
}

p,
small,
label {
  letter-spacing: 0.02em;
}

.btn {
  letter-spacing: 0.05em;
}

.list-from-to {
  padding-left: 1.25rem;

  li {
    position: relative;

    &::marker {
      font-size: 1.5rem;
      color: $border-color;
    }

    &:not(:first-of-type) {
      &::after {
        position: absolute;
        bottom: calc(100% - 1.5rem);
        left: -1.25em;
        z-index: 0;
        width: 1px;
        height: calc(100% - 0.5rem);
        content: '';
        background-color: $border-color;
      }
    }
  }
}

.list-check {
  li {
    list-style-image: url('../assets/icons/8/check.svg');
  }
}

.btn-outline-dark {
  --bs-btn-hover-color: var(--bs-dark);
  --bs-btn-hover-bg: #{shade-color($light, 10%)};
}

.btn-white {
  @include button-variant($white, $dark, $dark, $light, $dark, $dark);

  &.active {
    color: $white;
    background: $link-color;
  }
}

.card-clickable {
  color: initial;
  text-decoration: none;

  &:hover {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: initial;
      text-decoration: underline;
    }
  }
}

mark {
  padding-left: 0;
  padding-right: 0;
  font-weight: 600;
  text-decoration: underline;
}
