// Variabili di BS 5.1.3 Modificate in base al DS Costa
$font-family-base: 'Poppins', sans-serif;
$enable-rounded: false;
$enable-caret: false;
$enable-shadows: true;
// COLORS
$blue: #0061bf;
$green: #8fe85f;
$cyan: #0080d1;
$yellow: #ffd100;
$red: #ff5349;
$black: #292929;
$white: #fff;
$body-color: #292929;
$link-color: #0091f0;

$primary: $yellow;
$secondary: $blue;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: #f5f5f5;
$dark: #212529;

// Color Grayscale
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
//$black: #000;
// END COLORS

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'inst': 'inst',
  'abu-dhabi': 'abu-dhabi',
  'caraibi': 'caraibi',
  'grandi-crociere': 'grandi-crociere',
  'mediterraneo': 'mediterraneo',
  'nord-europa': 'nord-europa',
  'oriente': 'oriente',
  'sud-america': 'sud-america',
);

$colors: (
  'blue': $blue,
  'red': $red,
  'yellow': $yellow,
  'green': $green,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
);

$custom-colors: (
  'inst': #f9b000,
  'promo': #d45418,
  'abu-dhabi': #e4b66a,
  'caraibi': #6daac9,
  'grandi-crociere': #588d9c,
  'mediterraneo': #008aba,
  'nord-europa': #9abf62,
  'oriente': #e26768,
  'sud-america': #e18535,
  'active': #77bc1f,
  'dynamic': #cf0a2c,
  'energy': #ae9962,
  'reactive': #cd66ce,
  'top': #0055b7,
  'supertop': #003864,
  'newagency': $white,
  'dealer': $primary,
  'excellence': $primary,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 3.725;
$h2-font-size: $font-size-base * 2.25;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.125;
$h5-font-size: $font-size-base;
$headings-font-weight: 600;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  xs: 0.8125rem,
  xxs: 0.6875rem,
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
  7: $spacer * 5,
  8: $spacer * 7.5,
);

$border-color: #e0e0e0;
$border-radius: 0;
$card-border-color: $border-color;
$hr-border-color: $border-color;
$input-btn-padding-y: 0.625rem;
$input-btn-padding-x: 0.75rem;
$input-border-color: #a9a9a9;
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-btn-focus-color-opacity: 0.75;
$input-btn-focus-color: rgba($info, $input-btn-focus-color-opacity);
$input-border-radius: $border-radius;
$form-check-input-width: 1.5em;

$input-group-addon-bg: #fff;
$input-group-addon-border-color: $input-border-color;

$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-border-radius: $border-radius;

$border-radius-sm: 0;
$border-radius-lg: 1rem;

$breadcrumb-bg: transparent;

$btn-font-weight: 500;
$btn-padding-y: 0.625rem;
$btn-padding-x: 2rem;
// $btn-padding-y-lg: .75rem;
// $btn-padding-x-lg: 4rem;
// $btn-padding-y-sm: .5rem;
// $btn-padding-x-sm: .875rem;
$btn-font-size: 0.8125rem;
$btn-font-size-sm: 0.6875rem;
$btn-font-size-lg: 0.875rem;
$btn-line-height: 1.375rem;

$navbar-light-color: $black;
$navbar-light-active-color: $black;
$navbar-nav-link-padding-x: 1rem;

$toast-border-color: #e0e0e0;
$toast-background-color: $white;
$toast-header-background-color: $white;
$toast-header-border-color: transparent;

$modal-content-border-color: $border-color;
$modal-header-border-color: $border-color;
$modal-footer-border-color: transparent;
$modal-header-border-width: 1px;
$modal-footer-border-width: 0;
$modal-content-border-width: 0;

$progress-bar-bg: theme-color('secondary');

$badge-font-size: 0.6875rem;
$badge-font-weight: 600;
$badge-color: $white;
$badge-padding-y: 0.5rem;
$badge-padding-x: 1rem;
$badge-border-radius: 0;

$breadcrumb-divider: quote('>');
$component-active-bg: #0080d1;

$alert-link-font-weight: 500;
$alert-border-radius: 0.5rem;
$alert-padding-y: map-get($spacers, 4);
$alert-padding-x: map-get($spacers, 5);

$jumbotron-bg: transparent;
$font-weight-light: 100;
$close-font-weight: $font-weight-light;

$table-striped-bg-factor: 0.05;
$table-striped-bg: rgba($blue, $table-striped-bg-factor);
//$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$table-group-separator-color: $cyan;

// $box-shadow: 0 10px 15px rgb(0, 0, 0, .15);

// $box-shadow: 0 0.625rem 0.9375rem rgba($black, 0.15);
$box-shadow: 0 0 10px rgb(0 0 0 / 12%);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);

$dropdown-box-shadow: 0 0.625rem 0.9375rem rgba($black, 0.15);

$nav-pills-link-active-bg: $primary;
$nav-pills-link-active-color: $black;

$placeholder-opacity-max: 0.2;
$placeholder-opacity-min: 0.1;

$form-select-indicator-color: $body-color;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");

$accordion-button-active-color: $body-color;
