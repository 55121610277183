@font-face {
  font-family: 'costaglyph';
  src: url('../assets/fonts/costaglyph.ttf?sw713c') format('truetype'), url('../assets/fonts/costaglyph.woff?sw713c') format('woff'),
    url('../assets/fonts/costaglyph.svg?sw713c#costaglyph') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='iko-'],
[class*=' iko-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'costaglyph' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.iko-bed-double:before {
  content: '\e907';
}
.iko-cabin-alt:before {
  content: '\e908';
}
.iko-cabin:before {
  content: '\e906';
}
.iko-filters:before {
  content: '\e905';
}
.iko-share-outline-1:before {
  content: '\e904';
}
.iko-euro-circled:before {
  content: '\e903';
}
.iko-difficulty-easy:before {
  content: '\e900';
}
.iko-difficulty-hard:before {
  content: '\e901';
}
.iko-difficulty-medium:before {
  content: '\e902';
}
.iko-circle:before {
  content: '\e1900';
}
.iko-warn:before {
  content: '\e1901';
}
.iko-close:before {
  content: '\e1902';
}
.iko-close-2:before {
  content: '\e1982';
}
.iko-arrow-left-mini:before {
  content: '\e1917';
}
.iko-arrow-left:before {
  content: '\e193c';
}
.iko-arrow-down-thick:before {
  content: '\e1903';
}
.iko-arrow-up:before {
  content: '\e1958';
}
.iko-arrow-down:before {
  content: '\e1965';
}
.iko-arrow-right-thick:before {
  content: '\e1919';
}
.iko-arrow-right-thin:before {
  content: '\e1987';
}
.iko-arrow-right:before {
  content: '\e1980';
}
.iko-play-circle:before {
  content: '\e198c';
}
.iko-play:before {
  content: '\e1977';
}
.iko-fbook:before {
  content: '\e192a';
}
.iko-fbook-2:before {
  content: '\e1908';
}
.iko-gplus:before {
  content: '\e1909';
}
.iko-linkedin:before {
  content: '\e192b';
}
.iko-instagram:before {
  content: '\e190a';
}
.iko-pinterest:before {
  content: '\e190b';
}
.iko-twitter:before {
  content: '\e190c';
}
.iko-youtube:before {
  content: '\e190d';
}
.iko-whatsapp:before {
  content: '\e196d';
}
.iko-fbmessanger:before {
  content: '\e196b';
}
.iko-fbmessanger-2:before {
  content: '\e192d';
}
.iko-twitter-circle:before {
  content: '\e192e';
}
.iko-mail-circle:before {
  content: '\e192c';
}
.iko-whatsapp-circle:before {
  content: '\e192f';
}
.iko-web:before {
  content: '\e190e';
}
.iko-link:before {
  content: '\e1992';
}
.iko-hamb-big:before {
  content: '\e1933';
}
.iko-hamb:before {
  content: '\e190f';
}
.iko-hamb-mini:before {
  content: '\e1912';
}
.iko-phone-4:before {
  content: '\e1913';
}
.iko-phone-ring:before {
  content: '\e1979';
}
.iko-phone:before {
  content: '\e1985';
}
.iko-phone2:before {
  content: '\e1994';
}
.iko-phone-thin:before {
  content: '\e1948';
}
.iko-mail:before {
  content: '\e1993';
}
.iko-mail-full:before {
  content: '\e191d';
}
.iko-reload:before {
  content: '\e1918';
}
.iko-pencil:before {
  content: '\e191c';
}
.iko-clock:before {
  content: '\e191f';
}
.iko-photo:before {
  content: '\e1920';
}
.iko-maximize:before {
  content: '\e1921';
}
.iko-reload-2:before {
  content: '\e1922';
}
.iko-quotes:before {
  content: '\e1923';
}
.iko-still:before {
  content: '\e1924';
}
.iko-play-2:before {
  content: '\e1925';
}
.iko-no-volume:before {
  content: '\e1926';
}
.iko-hi-volume:before {
  content: '\e1927';
}
.iko-low-volume:before {
  content: '\e1928';
}
.iko-card:before {
  content: '\e1930';
}
.iko-costa-for-you:before {
  content: '\e1932';
}
.iko-anchor:before {
  content: '\e1934';
}
.iko-settings:before {
  content: '\e1936';
}
.iko-lens:before {
  content: '\e1938';
}
.iko-arrow-down-huge:before {
  content: '\e193d';
}
.iko-plus-circle:before {
  content: '\e1942';
}
.iko-minus-circle:before {
  content: '\e1929';
}
.iko-warning:before {
  content: '\e1946';
}
.iko-share:before {
  content: '\e1949';
}
.iko-warn-cricle:before {
  content: '\e194b';
}
.iko-checked-circle:before {
  content: '\e194a';
}
.iko-checked-big:before {
  content: '\e194d';
}
.iko-checked-big-3:before {
  content: '\e1947';
}
.iko-checked-mini:before {
  content: '\e191e';
}
.iko-info-circle:before {
  content: '\e194e';
}
.iko-flash:before {
  content: '\e1950';
}
.iko-person-mini:before {
  content: '\e1971';
}
.iko-profile:before {
  content: '\e1931';
}
.iko-family:before {
  content: '\e1953';
}
.iko-couple:before {
  content: '\e1943';
}
.iko-people:before {
  content: '\e1954';
}
.iko-lens-back:before {
  content: '\e1957';
}
.iko-plus:before {
  content: '\e1959';
}
.iko-couple-2:before {
  content: '\e195a';
}
.iko-agenda:before {
  content: '\e195b';
}
.iko-calendar:before {
  content: '\e194c';
}
.iko-agenda-2:before {
  content: '\e1952';
}
.iko-minus-2:before {
  content: '\e195c';
}
.iko-calendar-2:before {
  content: '\e1935';
}
.iko-clock-2:before {
  content: '\e195d';
}
.iko-place:before {
  content: '\e195e';
}
.iko-anchor-2:before {
  content: '\e195f';
}
.iko-arrow-left-long:before {
  content: '\e1960';
}
.iko-arrow-right-long:before {
  content: '\e1961';
}
.iko-lens-2:before {
  content: '\e1962';
}
.iko-heart:before {
  content: '\e1963';
}
.iko-heart-filled:before {
  content: '\e963';
}
.iko-dropdown:before {
  content: '\e1964';
}
.iko-share-2:before {
  content: '\e1966';
}
.iko-copy:before {
  content: '\e1968';
}
.iko-info:before {
  content: '\e1969';
}
.iko-info-circled:before {
  content: '\e1914';
}
.iko-attach:before {
  content: '\e196a';
}
.iko-exfilter:before {
  content: '\e196c';
}
.iko-remind:before {
  content: '\e196e';
}
.iko-annotate:before {
  content: '\e196f';
}
.iko-exit:before {
  content: '\e1970';
}
.iko-plane-2:before {
  content: '\e1941';
}
.iko-plane:before {
  content: '\e194f';
}
.iko-bus-2:before {
  content: '\e1972';
}
.iko-bus:before {
  content: '\e1951';
}
.iko-train:before {
  content: '\e1999';
}
.iko-ship-2:before {
  content: '\e1955';
}
.iko-ship:before {
  content: '\e193e';
}
.iko-bus-3:before {
  content: '\e193f';
}
.iko-warn-3:before {
  content: '\e1973';
}
.iko-ticket:before {
  content: '\e1967';
}
.iko-hidden:before {
  content: '\e1974';
}
.iko-visible:before {
  content: '\e1975';
}
.iko-exit-2:before {
  content: '\e1976';
}
.iko-excursion:before {
  content: '\e1940';
}
.iko-place-2:before {
  content: '\e1945';
}
.iko-place-3:before {
  content: '\e1937';
}
.iko-place-4:before {
  content: '\e1995';
}
.iko-lock:before {
  content: '\e197a';
}
.iko-checked-4:before {
  content: '\e197b';
}
.iko-discount:before {
  content: '\e197c';
}
.iko-discount-2:before {
  content: '\e1944';
}
.iko-semi-arrow-left:before {
  content: '\e197d';
}
.iko-semi-arrow-right:before {
  content: '\e197e';
}
.iko-arrow-both:before {
  content: '\e197f';
}
.iko-reload-3:before {
  content: '\e1981';
}
.iko-semi-menu:before {
  content: '\e1984';
}
.iko-lens-3:before {
  content: '\e1986';
}
.iko-certified:before {
  content: '\e1988';
}
.iko-attach-3:before {
  content: '\e1989';
}
.iko-resize-area:before {
  content: '\e198a';
}
.iko-picture:before {
  content: '\e198d';
}
.iko-smile:before {
  content: '\e19df';
}
.iko-care:before {
  content: '\e1978';
}
.iko-heart-3:before {
  content: '\e193a';
}
.iko-heart-4:before {
  content: '\e1906';
}
.iko-heart-5:before {
  content: '\e198e';
}
.iko-hearth-empty:before {
  content: '\e198f';
}
.iko-hearth-empty-2:before {
  content: '\e1939';
}
.iko-exfilter-2:before {
  content: '\e1990';
}
.iko-arrow-right-2:before {
  content: '\e198b';
}
.iko-arrow-right-3:before {
  content: '\e1991';
}
.iko-arrow-right-5:before {
  content: '\e19f9';
}
.iko-plus-big:before {
  content: '\e1996';
}
.iko-minus-bug:before {
  content: '\e1997';
}
.iko-delete:before {
  content: '\e1998';
}
.iko-female:before {
  content: '\e19dd';
}
.iko-male-female:before {
  content: '\e19d7';
}
