@each $state, $value in $theme-colors {
  $alert-background: $white;
  $alert-border: #d9d9d6;
  $alert-color: $dark;

  @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
    $alert-color: mix($value, color-contrast($alert-background), abs($alert-color-scale));
  }

  .alert-#{$state} {
    @include alert-variant($alert-background, $alert-border, $alert-color);
    font-size: 0.785rem;
    color: var(--bs-grey-600);
    border-radius: $alert-border-radius;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      content: '';
      background: var(--bs-#{$state});
      border-radius: $alert-border-radius 0 0 $alert-border-radius;
    }
  }
}
